import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../Store";
import { data } from "./data";
import LOGOIMG from "../../assets/images/logo.png";
import { Container, MenuItems } from "./styled.components";
import { slideDown } from "./transition";
import { useGlobalSelector } from "../../features/useSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "./Dropdown";
import useGlobalService from "../../Hooks/useGlobalService";

export default function Navigation() {
  const { setDropdownId, setIsMenuActive, setIsDropdownActive } =
    useGlobalService();
  const { currentRoute } = useSelector((state: RootState) => state.Routes);
  const [scrolled, setScrolled] = useState(false);
  const { isLoaded, dropdownId, isMenuActive } = useGlobalSelector();
  const navigate = useNavigate();

  useEffect(() => {
    window.onscroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 100) {
        setScrolled(true);
      } else if (scrollY < 100) {
        setScrolled(false);
      }
    };
  }, []);

  const handleClick = () => {
    setIsMenuActive(!isMenuActive);
  };

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.smooth ? (
      <button
        key={idx}
        onClick={() => {
          setIsMenuActive(false);
          if (currentRoute !== "/") {
            navigate("/#" + item.name);
            return;
          }
          document.getElementById(item.name)?.scrollIntoView({
            behavior: "smooth",
          });
        }}
        onMouseOver={() => {
          setDropdownId(-1);
        }}
        className={`link  hover:text-primary
        ${currentRoute.includes(item.name) && "active"}
        ${currentRoute !== "/" && "v2"}
        `}
      >
        {item.name}
      </button>
    ) : item.to && !item.isAnchor ? (
      <Link
        key={idx}
        to={item.to}
        onClick={() => {
          setIsMenuActive(false);
          setDropdownId(-1);
        }}
        onMouseOver={() => {
          if (window.innerWidth <= 768) return;
          setDropdownId(-1);
        }}
        className={`link relative whitespace-nowrap hover:text-primary
        ${currentRoute === item.to && "active"}
        ${currentRoute !== "/" && "v2"}
        `}
      >
        {item.name}
      </Link>
    ) : item.dropdown ? (
      <div
        key={idx}
        onClick={(e) => {
          // console.log(dropdownId);
          setDropdownId(dropdownId !== idx ? idx : -1); // opens the dropdown

          const target = e.target as HTMLElement;

          if (target.textContent === item.name) {
            navigate(item.self ? item.self : "/");
          }
        }}
        onMouseOver={() => {
          if (window.innerWidth <= 768) return;

          setDropdownId(idx); // opens the dropdown
        }}
        className={`link relative cursor-pointer  hover:text-primary
         ${currentRoute === item.to && "active"}`}
      >
        <span
          onClick={(e) => {
            setDropdownId(-1);
            // setIsMenuActive(false);
          }}
          className={`link whitespace-nowrap ${
            currentRoute === item.to && "active"
          }`}
        >
          {item.name}
          <FontAwesomeIcon
            className="ml-[1vh]"
            icon={idx === dropdownId ? faChevronUp : faChevronDown}
          />
        </span>

        <Dropdown menuId={idx} data={item.dropdown} />
      </div>
    ) : (
      <a
        key={idx}
        target="_blank"
        rel="noreferrer"
        href={item.to} // route
        onClick={() => setIsMenuActive(false)}
        className={`link hover:text-primary 
        ${currentRoute === item.to && "active"} 
        ${currentRoute !== "/" && "v2"}`}
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container
      initial="hidden"
      variants={slideDown}
      animate={"visible"}
      className={`${scrolled ? "bg-dark" : "bg-transparent"} fixed top-0 left-0 py-[2vh] flex flex-col justify-center items-center z-50 w-full`}
    >
      <div className="flex flex-row justify-center items-center w-full">
        <div className="text-center px-[2vw]">
          <Link
            to="/"
            onClick={() => {
              setIsMenuActive(false);

              document.getElementById("home")?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <img
              className="w-[45vw] md:w-[20vw] mx-auto"
              src={LOGOIMG}
              alt="eem triplin"
            />
          </Link>
        </div>

        {/* desktop navigation */}
        <div className="justify-end items-center gap-x-[2vw] flex-row w-full hidden md:flex">
          {menuItems}
        </div>

        {/* burger */}
        <div
          className={`flex-col z-50 cursor-pointer ml-auto rounded-md p-2 flex md:hidden`}
          aria-label="button"
          onClick={handleClick}
        >
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isMenuActive ? "rotate-45" : "rotate-0"
            } transition-all`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
              isMenuActive ? "hidden" : "block"
            }`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isMenuActive ? "-rotate-45 absolute" : "rotate-0 mt-1"
            } transition-all`}
          />
        </div>
      </div>
      {/* tablet navigation */}
      <MenuItems
        className={`absolute bg-dark top-0 w-screen uppercase transition-all duration-700 justify-center flex items-center flex-col md:hidden`}
        isActive={isMenuActive}
      >
        {menuItems}
      </MenuItems>

      {/* tablet navigation */}
      {/* <div
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsDropdownActive(!isDropdownActive);
            console.log((e.target as any).tagName);
            if (
              (e.target as any).tagName === "A" ||
              (e.target as any).tagName === "SPAN" ||
              (e.target as any).tagName === "path" ||
              (e.target as any).tagName === "svg"
            )
              return;

            setDropdownId(-1);
          }}
          className={`mobile-nav uppercase justify-center items-center flex-col flex fixed top-0 bg-opacity-80 transition-all duration-500 w-full h-screen bg-primary md:hidden
            ${isMenuActive ? "right-0" : "-right-full"}
        `}
        >
          {menuItems}
        </div> */}
    </Container>
  );
}
