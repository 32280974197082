import { Variants } from "framer-motion";
import { EASING, TRANSITION_DURATION } from "../../constants";

export const slideDown: Variants = {
  hidden: {
    opacity: 0,
    y: "-100%",
    zIndex: 0,
  },
  visible: {
    opacity: 100,
    y: "0%",
    zIndex: 1000,
    transition: {
      ease: EASING,
      duration: 1,
    },
  },
};
