import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { Navigation } from "./components";
import Footer from "./components/Footer/Footer";

export default function App() {
  return (
    <>
      <Router>
        <Navigation />
        <Routes>
          <Route path="*" element={<AppRoutes />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
