import { IconDefinition, faHome } from "@fortawesome/free-solid-svg-icons";
import { RoutePattern } from "../../routes/RoutePattern";

export interface RouteTypes {
  name: string;
  to?: string;
  linked?: string;
  smooth?: boolean;
  isAnchor?: boolean;
  dropdown?: RouteTypes[];
  self?: string;
  nextTo?: string;
}

export const data: RouteTypes[] = [
  {
    name: "home",
    smooth: true,
  },
  {
    name: "videos",
    smooth: true,
  },
  {
    name: "music",
    isAnchor: true,
    to: "https://elsnappo.lnk.to/bio",
  },
  {
    name: "game",
    isAnchor: true,
    to: "https://game.elsnappo1900.com/",
  },
];
