import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isBurgerActive: false,
  isLoaded: false,
  isDropdownActive: false,
  dropdownId: -1,
  isMenuActive: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setVideoPopupState: (state, { payload }) => {
      state.isLoaded = payload;
    },

    setIsLoaded: (state, { payload }) => {
      state.isLoaded = payload;
    },
    setIsMenuActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isMenuActive = payload;
    },
    setIsDropdownActive: (state, { payload }) => {
      state.isDropdownActive = payload;
    },
    setDropdownId: (state, { payload }) => {
      state.dropdownId = payload;
    },
  },
});

export const {
  setIsBurgerActive,
  setIsLoaded,
  setDropdownId,
  setIsDropdownActive,
  setIsMenuActive,
} = globalSlice.actions;
