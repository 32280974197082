import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faApple,
  faDeezer,
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import TIDAL_IMG from "../../assets/images/tidal-icon.png";
import PANDORA_IMG from "../../assets/images/pandora-icon.png";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faInstagram,
    link: "https://www.instagram.com/elsnappo1900/",
  },
  {
    icon: faXTwitter,
    link: "https://x.com/elsnappo1900_",
  },
  {
    icon: faTiktok,
    link: "https://www.tiktok.com/@elsnappo1900",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/@Elsnappo1900",
  },
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/0GJvhbSzCx9kT4jb7yd4eS?si=8FFDuYzGRFyM-L8utmRo3w",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/el-snappo/1563800351",
  },
];
