import { dispatch } from "../../Store";
import {
  setDropdownId,
  setIsBurgerActive,
  setIsDropdownActive,
  setIsLoaded,
  setIsMenuActive,
} from "../../features/Global/Global.slice";

export default class GlobalService {
  setIsBurgerActive(value: boolean) {
    dispatch(setIsBurgerActive(value));
  }

  setIsLoaded(payload: boolean) {
    dispatch(setIsLoaded(payload));
  }

  setIsDropdownActive(payload: number) {
    dispatch(setIsDropdownActive(payload));
  }

  setDropdownId(payload: number) {
    dispatch(setDropdownId(payload));
  }

  setIsMenuActive(payload: boolean) {
    dispatch(setIsMenuActive(payload));
  }
}
