import Showcase from "./Showcase";
import Videos from "./Videos";

const Home = () => {
  return (
    <div
      id="home"
      className="relative flex flex-col justify-center items-center"
    >
      <Showcase />
      <Videos />
    </div>
  );
};

export default Home;
