import SocialIcons from "../SocialIcons/SocialIcons";

export default function Footer() {
  return (
    <div
      id="footer"
      className="bg-none uppercase flex flex-col md:flex-row justify-around items-center text-center p-5"
    >
      <span className="lg:mr-5">El Snappo &copy;</span>
    </div>
  );
}
