import { motion } from "framer-motion";
import styled from "styled-components";

export const MenuItems = styled.div<{ isActive: boolean }>`
  height: ${({ isActive }) => (isActive ? "100vh" : "0vh")};

  a,
  button {
    opacity: ${({ isActive }) => (isActive ? "100%" : "0%")};
    display: ${({ isActive }) => (isActive ? "block" : "none")};
  }

  div {
    opacity: ${({ isActive }) => (isActive ? "100%" : "0%")};
    display: ${({ isActive }) => (isActive ? "block" : "none")};
  }
`;

export const Container = styled(motion.div)`
  /* z-index: 100; */
`;
