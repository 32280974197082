import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import { RoutePattern } from "../../routes/RoutePattern";

export default function Videos() {
  return (
    <div
      id={RoutePattern.VIDEOS}
      className="relative flex flex-col justify-center items-center w-full py-[7vh] min-h-screen"
    >
      <h2 className="heading-2">VIDEOS</h2>
      <div className="mt-[4vh] w-5/6 mx-auto h-full grid grid-cols-1 gap-[2vw] md:w-3/4">
        <YoutubeSlider
          data={[]}
          playlistId="PL53EmiDHRxN7DlTRR_ZEQaP4vaSIxP8UD"
        />
      </div>
    </div>
  );
}
